import {Component, Inject} from '@angular/core';
import {MatBottomSheetRef} from '@angular/material';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material';
import {Submittal} from '../../../models';

@Component({
  selector: 'app-mobile-submittal-view',
  templateUrl: './mobile-submittal-view.component.html',
  styleUrls: ['./mobile-submittal-view.component.css']
})
export class MobileSubmittalViewComponent {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<MobileSubmittalViewComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public localSubmittal: Submittal,
  ) { }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }
}
