import {Component, OnInit} from '@angular/core';
import {PageService} from '../../services/page.service';
import {Hero, Page} from '../../models';
import {HeroService} from '../../services/hero.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public page: Page;
  public hero: Hero;
  public loaded = false;
  public language = 'en';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pageService: PageService,
    private heroService: HeroService,
  ) {
    // @ts-ignore
    router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationEnd) {
        this.loadPage();
      }
    });
  }

  public loadPage() {
    this.pageService.get('home', {lang: this.language}).subscribe(page => {
      this.page = page;
      this.heroService.list({lang: this.language}).subscribe(heroes => {
        this.hero = heroes[0];
        this.loaded = true;
      });
    });
    this.route.url.subscribe(url => {
      this.language = url[0].path;
    });
  }

  ngOnInit() {
    this.loadPage();
  }

}
