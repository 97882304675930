import {Observable} from 'rxjs';

export class Base {
  id: number;
  url: string;
  created_at: string;
  updated_at: string;
  is_deleted: boolean;
}

export class SiteNavigation {
  items: MenuItem[];
  public constructor(items: MenuItem[]) {
    this.items = items;
  }
}

export class Named extends Base {
  name: string;
}

export class Described extends Named {
  description: string;
}

export class Content extends Named {
  content: string;
}

export class Product extends Described {
  subcategory: Subcategory;
  image: string;
  has_comparison: boolean;
  features: Feature[] = Array();
  tags: ProductTag[] = Array();
  docs: DocList[] = Array();
  certifications: Certification[] = Array();
  listed: boolean;
}

export class Certification {
  name: string;
  image: string;
}

export class DocList {
  title: string;
  documents: Document[] = Array();
}

export class Document {
  name: string;
  file: string;
}

export class Category extends Described {
  image: string;
  color: string;
  rgba: string;
  slug: string;
}

export class Subcategory extends Described {
  image: string;
  category: Category;
}

export class FeatureType extends Named {}

export class Feature extends Described {
  featuretype: FeatureType;
}

export class Assembly extends Named {}
export class Penetrant extends Named {}
export class Sleeve extends Named {}
export class PipeCover extends Named {}

export class Firestop extends Named {
  fire_resistance: number;
  thermal_resistance: number;
  fire_resistance_type: string;
  pressure_tested: boolean;
  tags: FirestopTag[] = Array();
  documents: Document[] = Array();
  image: string;
}

export class Penetration extends Firestop {
  minimum_annular_space: number;
  maximum_annular_space: number;
  nominal_penetrant_size: number;
  assemblies: Assembly[] = Array();
  penetrants: Penetrant[] = Array();
  sleeves: Sleeve[] = Array();
  products: Product[] = Array();
  docs: DocList[] = Array();
}

export class JointType extends Named {}
export class Substrate extends Named {}

export class Joint extends Firestop {
  minimum_width: number;
  maximum_width: number;
  type: JointType;
  substrates: Substrate[] = Array();
  products: Product[] = Array();
  docs: DocList[] = Array();
}

export class ProductTag extends Named {}
export class FirestopTag extends Named {}

export class TechDoc extends Named {
  file: string;
  doc_type: string;
}

export class TechDocType extends Named {}

export class TechDocZipRequest extends Base {
  tech_docs: string[];
  file: string;
  error: string;
}

export class CallToAction extends Content {
  action: string;
  action_word: string;
}

export class Hero {
  left: CallToAction;
  right: CallToAction;
}

export class MenuItem {
  slug: string;
  name: string;
}

export class SiteInfo {
  footer_one: string;
  footer_two: string;
  footer_three: string;
  footer_four: string;
  tag_line: string;
  menu: MenuItem[] = Array();
  search_error: string;
  banner: Category[] = Array();
  google_analytics_id: string;
  contact_area: string;
}

export class Page extends Named {
  slug: string;
  content: string;
  sidebar: string;
  sidebar_width: number;
  sidebar_side: number;
  columns: Column[] = Array();
}

export class Column extends Named {
  order: number;
  content_blocks: ContentBlock[] = Array();
}

export class ContentBlock extends Content {
}

export class Media {
  file: string;
  title: string;
  alt: string;
}


export class Comparison extends Base {
  display_name: string;
  one_name: string;
  two_name: string;
  chart: Chart[];
}

export class Chart {
  title: string;
  elements: Element[];
}
export class Element {
  display_name: string;
  one_name: string;
  two_name: string;
  chart: Chart[];
}

export class Submittal {
  name: string;
  url: string;
  id: string;
  project: string;
  company_name: string;
  architect: string;
  queued_at: string;
  completed_at: string;
  pdf: string;
  has_error: boolean;
  error: string;
  joints: Joint[] = Array();
  penetrations: Penetration[] = Array();
  is_completed: boolean;
  is_queued: boolean;
  progress: number;
  // these are actually URLs!
  joint_ids: string[] = Array();
  penetration_ids: string[] = Array();
  form_factor = 'abridged';
}

export interface ILocalStorage {
  select(key: string, defaultValue: any): Observable<any>;
  set(key: string, value: any): void;
  remove(key: string): void;
}
