import {Component, Input} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SiteInfo} from '../../models';
import {SiteInfoService} from '../../services/site-info.service';
import {LocalStorageService} from '../../services/local-storage.service';

@Component({
  selector: 'app-basic-page',
  templateUrl: './basic-page.component.html',
  styleUrls: ['./basic-page.component.css']
})
export class BasicPageComponent {
  @Input() language = 'en';
  @Input() header = false;
  public panelOpenState = false;
  public siteInfo: SiteInfo;
  public searchText = 'Search';
  public searchQuery = '';
  constructor(
    private siteInfoService: SiteInfoService,
    private router: Router,
    private storage: LocalStorageService,
    private route: ActivatedRoute,
  ) {
    // @ts-ignore
    router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationEnd) {
        this.loadPage();
      }
    });
  }

  public loadPage() {
    this.route.queryParams.subscribe(params => {
      if (params['q']) {
        this.searchQuery = params['q'];
      }
    });
    this.route.url.subscribe(url => {
      this.language = url[0].path;
      this.siteInfoService.list({lang: this.language}).subscribe(info => {
        this.siteInfo = info[0];
        if (this.language === 'en') {
          this.searchText = 'Search';
        } else {
          this.searchText = 'Recherche';
        }
      });
    });
  }

  public doSearch(query: string) {
    this.router.navigateByUrl('/' + this.language + '/search?q=' + query);
  }
}
