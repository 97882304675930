import {Component} from '@angular/core';
import {Submittal} from '../../models';
import {SubmittalService} from '../../services/submittal.service';
import {LocalStorageService} from '../../services/local-storage.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MatBottomSheet} from '@angular/material';
import {MobileSubmittalViewComponent} from './mobile-submittal-view/mobile-submittal-view.component';
import {SubmittalViewComponent} from './submittal-view/submittal-view.component';

@Component({
  selector: 'app-submittal',
  templateUrl: './submittal.component.html',
  styleUrls: ['./submittal.component.css']
})

export class SubmittalComponent {
  public language = 'en';
  public loaded = false;
  public localSubmittal: Submittal;
  public submitted = false;
  public completed = false;

  constructor(
    private submittalService: SubmittalService,
    private storage: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private bottomSheet: MatBottomSheet
  ) {
    // @ts-ignore
    router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationEnd) {
        this.loadPage();
      }
    });
  }

  public remove(item: any) {
    this.localSubmittal.joints = this.localSubmittal.joints.filter(j => j.url !== item.url);
    this.localSubmittal.penetrations = this.localSubmittal.penetrations.filter(p => p.url !== item.url);
    this.storage.set('submittal', this.localSubmittal);
  }

  public clearSubmittal() {
    this.storage.remove('submittal');
    this.localSubmittal = new Submittal();
    this.submitted = false;
    this.completed = false;
    this.storage.set('submittal', this.localSubmittal);
  }

  private checkSubmittal() {
    setTimeout(() => {
      this.submittalService.get(this.localSubmittal.id, {lang: 'en'}).subscribe(submittal => {
        this.localSubmittal = submittal;
        if (submittal.is_completed) {
          this.completed = true;
        } else {
          this.checkSubmittal();
        }
      });
    }, 1000);
  }

  public showMobileSubmittal() {
    this.bottomSheet.open(MobileSubmittalViewComponent, {data: this.localSubmittal});
  }

  public loadPage() {
    this.storage.select('submittal').subscribe(local => {
      if (local) {
        if (local.id) {
          this.submitted = true;
          this.submittalService.get(local.id, {lang: this.language}).subscribe(submitted => {
            this.localSubmittal = submitted;
            this.loaded = true;
            this.checkSubmittal();
            }
          );
        } else {
          this.localSubmittal = local;
          this.loaded = true;
        }
      } else {
        this.localSubmittal = new Submittal();
        this.storage.set('submittal', this.localSubmittal);
        this.loaded = true;
      }
    });
  }
}
